import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VTooltip from "v-tooltip";
import VueYoutube from "vue-youtube";
import "./vee-validate";
import axios from "axios";


const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
Vue.config.productionTip = false;
Vue.use(VTooltip);
Vue.use(VueYoutube);

new Vue({
  router: router,
  store,
  render: (h) => h(App),
}).$mount("#app");
