import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import router from "./router";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  state: {
    token: localStorage.getItem("token") || "",
    AUTH: false,
    USER: {},
    STANDS_BDD: [  
    ],    
    MENU_OPEN :false,
    error: {
      display: false,
      msg: null,
    },
    SPONSORS : [],
    // MEDIAS_URL : 'http://localhost/fntp/pdfs'
    MEDIAS_URL : 'http://popup-tp-digital.fr/pdfs',
    SOUND : true
  },
  getters: {
    getAuth: (state) => {
      return state.AUTH;
    },
    getUser: (state) => {
      return state.USER;
    },
    getSponsors: (state) => {
      return state.SPONSORS;
    },
    getStand: (state) => (id) => {
      return state.STANDS_BDD.filter((item) => item.id == id);
    },
    getStands: (state) => {
      return state.STANDS_BDD;
    },
    getProducts: (state) => {
      const arrays = state.STANDS_BDD.map((stand) =>
        stand.products_cat.map((el) => el.name)
      );
      return [].concat.apply([], arrays);
    },
    getToken: (state) => {
      return state.token;
    },
    getMenuOpen: (state) => {
      return state.MENU_OPEN;
    },
  },
  mutations: {
    stopSound(state) {
      state.SOUND = false
      document.getElementById("sound").pause()
    },
    playSound(state) {
      state.SOUND = true
      document.getElementById("sound").play()
    },
    setProducts(state, item) {
      state.PRODUCTS_BDD = item;
    },
    setStands(state, item) {
      state.STANDS_BDD = item;
    },   
    setSponsors(state, item) {
      state.SPONSORS = item;
    },
    login(state, user) {
      //const token = localStorage.getItem("token");
      state.AUTH = true;
      state.USER = user;
      state.token = "todoToken";
     
    },
    refreshStands(state) {
      state.STANDS_BDD =    
       
      [
        //Monteur de réseaux électriques
          {
          id : 1,
          name :'Monteur de réseaux électriques',
          adress : {},
          videos : [
            {
              name :'Monteur de réseaux électriques',
              url : 'CTrEqJgytVA'
            }
          ],
          metiers : [
            {
              name : 'Monteur en éclairage public',
              url : 'fm_monteur_eclairage.pdf'
            },
            {
              name : 'Monteur réseaux électriques',
              url : 'fm_reseaux_electriques.pdf'
            },
          ],
          contact : {},
          diplomes : [
            {
              name : 'Monteur en éclairage public',
              url : 'monteur_eclairage.pdf'
            },
            {
              name : 'Monteur réseaux électriques',
              url : 'monteur_eclairage.pdf'
            },
          ],
          specialties : [],
          products_cat : [],
        },
        //Constructeur de route
        {
          id : 2,
          name :'Constructeur de routes',
          adress : {},
          videos : [
            {
              name : 'Le métier de constructeur de routes',
              url : 'BwoUuCpMbGk'
            },
            {
              name : 'Constructeur en voirie urbaine',
              url : 'cecg4pDQDE0'
            },
          ],
          metiers : [

            {
              name : 'CONSTRUCTEUR DE ROUTE',
              url : 'constructeur_de_routes.pdf'
            },
            {
              name : 'CONSTRUCTEUR EN VOIRIE URBAINE',
              url : 'constructeur_en_voirie_urbaine.pdf'
            },
          ],
          contact : {},
          diplomes : [
            {
              name : 'OU SE FORMER CONSTRUCTEUR ROUTES',
              url : 'ou_se_former.pdf'
            },

          ],
          specialties : [],
          products_cat : [],
        },
        //Constructeur en ouvrages d'art
        {
          id : 3,
          name :'Constructeur en ouvrages d\'art',
          adress : {},
          videos : [
            {
              name :'Constructeur en ouvrages d’art',
              url : '-nRRgpeihhs'
            }
          ],
          metiers : [
            {
              name : 'CONSTRUCTEUR EN OUVRAGEs D\'ART',
              url : 'fm_constructeur_en_ouvrage_art.pdf'
            },
          ],
          contact : {},
          diplomes : [
            {
              name : 'OU SE FORMER CONSTRUCTEUR EN OUVRAGEs D\'ART',
              url : 'constructeur_en_ouvrage_art.pdf'
            },
          ],
          specialties : [],
          products_cat : [],
        },
        //Geometre
        {
          id : 4,
          name :'Géomêtre - Topographie',
          adress : {},
          videos : [
            {
              name : 'Le métier de géomètre',
              url : 'Vw81_vu-BCQ'
            }
          ],
          metiers : [
            {
              name : 'GEOMETRE TOPOGRAPHE',
              url : 'fm_geometre_topographe.pdf'
            },
          ],
          contact : {},
          diplomes : [
            {
              name : 'OU SE FORMER GEOMETRE TOPOGRAPHE',
              url : 'geometre_topo.pdf'
            },
          ],
          specialties : [],
          products_cat : [],
        },
        //Réseaux canalisation
        {
          id : 5,
          name :'Constructeur de réseaux de canalisations',
          adress : {},
          videos : [
            {
              name : 'Constructeur de réseaux de canalisations',
              url : 'NOvoAGb-uuE'
            },
          ],
          metiers : [
            {
              name : 'FMCONSTRUCTEUR EN RÉSEAUX DE CANALISATIONS',
              url : 'fmconstructeur_en_reseaux_de_canalisations.pdf'
            },
          ],
          contact : {},
          diplomes : [
            {
              name : 'constructeur de canalisations',
              url : 'constructeur_de_canalisations.pdf'
            },
          ],
          specialties : [],
          products_cat : [],
        },
        //Stage Alternance
        {
          id : 6,
          name :'Stage // Alternance',
          adress : {},
          videos : [],
          metiers : [],
          contact : {},
          diplomes : [],
          specialties : [],
          products_cat : [],
        },
        //Encadrement
        {
          id : 7,
          name :'Encadrement',
          adress : {},
          videos : [
            {
              name : 'Chef d\'équipe',
              url : 'px6lqbqHyFs'
            },
            {
              name : 'Chef de chantier',
              url : '-n8AChit8Sw'
            },
            {
              name : 'Conducteur de travaux',
              url : '2DyTsTxhNDE'
            },
          ],
          metiers : [
            {
              name : 'Chef d\'équipe',
              url : 'fm_chef_equipe.pdf'
            },
            {
              name : 'Chef de chantier',
              url : 'fm_chef_chantier.pdf'
            },
            {
              name : 'Conducteur de travaux',
              url : 'fm_conducteur.pdf'
            },
          ],
          contact : {},
          diplomes : [
            {
              name : 'Chef d\'équipe',
              url : 'chef_equipe.pdf'
            },
            {
              name : 'Chef de chantier',
              url : 'chef_chantier.pdf'
            },
            {
              name : 'Conducteur de travaux',
              url : 'conducteur.pdf'
            },
          ],
          specialties : [],
          products_cat : [],
        },
        //Mécanicien d\'engins de travaux publics
        {
          id : 8,
          name :'Mécanicien d\'engins de travaux publics',
          adress : {},
          videos : [
            {
              name : 'Le métier de mécanicien d\'engins',
              url : 'jwI94OZH8eY'
            },
          ],
          metiers : [
            {
              name : 'Mécanicien d\'engins',
              url : 'fm_meca_engins.pdf'
            },
          ],
          contact : {},
          diplomes : [
            {
              name : 'Mécanicien d\'engins',
              url : 'meca_engins.pdf'
            },
          ],
          specialties : [],
          products_cat : [],
        },
        //Conducteur d\'engins de travaux publics
        {
          id : 9,
          name :'Conducteur d\'engins de travaux publics',
          adress : {},
          videos : [
            {
              name : 'Le métier de conducteur d\'engins',
              url : '3QWc7z-ARr8'
            },
          ],
          metiers : [
            {
              name : 'Conducteur d\'engins',
              url : 'fm_conducteur_engins.pdf'
            },
          ],
          contact : {},
          diplomes : [
            {
              name : 'Conducteur d\'engins',
              url : 'conducteur_engins.pdf'
            },
          ],
          specialties : [],
          products_cat : [],
        },
        //empty stand 10
        {
          id : 10
        },
        //empty stand 11
        {
          id : 11
        },        
        //empty stand 12
        {
          id : 12
        },

      ]
    },
    errorClose(state) {
      state.error.display = false;
      state.error.msg = null;
    },
    setError(state, payload) {
      state.error = payload;
    },
    logout(state) {
      localStorage.removeItem("token");
      localStorage.clear();
      delete axios.defaults.headers.common["Authorization"];
      //Reset STORE
      state.token = null;
      state.USER = {};
      state.AUTH = false;      
      state.STANDS_BDD = [];
      if (router.currentRoute.path != "/login" && router.currentRoute.path != "/") {
        router.push("/");
      }
    },
    setMenuOpen(state) {
      state.MENU_OPEN = !state.MENU_OPEN;
    },
    closeMenu(state) {
      state.MENU_OPEN = false;
    },
  },
  actions: {

    sendContactMail(context, formData) {
      return axios
        .post(this.state.BASE_URL + "/convention/contact", formData)
        .then(() => {
          return "succes";
        })
        .catch((error) => {
          return "An error has occured: " + error;
        });
    },
    sendNetworkMail(context, formData) {
      return axios
        .post(this.state.BASE_URL + "/convention/contact_net", formData)
        .then(() => {
          return "succes";
        })
        .catch(() => {
          return "An error has occured";
        });
    },
  },
});
