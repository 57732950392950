<template>
  <div class="footer-light">
    © Copyright {{ new Date().getFullYear() }} - FRTP Occitanie - <a href="#">Mentions légales</a> - Tous droits réservés - Conçu et développé par <a href="https://synthes3d.com" target="_blank">SYNTYHES'3D</a>
  </div>
</template>

<script>
export default {
    name : 'FooterLight',
}
</script>

<style lang="scss" scoped>
  .footer-light {
    text-align:center;
    justify-content: center;
    background:#fff;
    padding:20px;
    color:$text-color;
  }

  .footer-light a {
    color:$text-color;
    text-decoration: none;
  }

  .footer-light a:hover {
    text-decoration: underline;
  }
</style>