<template>
    <div class="header-light">
        <img src="./../assets/img/logo-popup.png" class="hide-resp" alt="logo POPUP" />
        <img src="./../assets/img/logo-bienvenue.png" class="hide-resp" alt="logo Bienvenue" />
        <img src="./../assets/img/logo-travaux-publics.png" alt="logo TP" />
    </div>
</template>

<script>
export default {
    name : 'HeaderLight'
}
</script>

<style lang="scss" scoped>
    .header-light {
        display: flex;
        justify-content: center;
        align-items: center;
        height:140px;
    }

    .header-light img {
        height:110px;
        width:auto;
        margin:0 20px;
    }

    @media screen and (max-width:768px) {
        .hide-resp {
            display:none;
        }
    }
</style>