<template>
  <div class="header-container">
    <div class="header-sound-control">
      <audio id="sound">
        <source src="./../assets/musique/voix_off.mp3" type="audio/mp3" >
      </audio>
      <div class="audio-contoller">
        <img class="sound-icon" src="./../assets/img/icon_sound_on.png" v-if="$store.state.SOUND" @click="soundSwitch" />
        <img class="sound-icon" src="./../assets/img/icon_sound_off.png" v-else @click="soundSwitch" />
      </div>
    </div>

    <div class="header-box">

      <router-link to="/home" class="logo-header">
        <img src="./../assets/img/logo-menu.png" alt="main logo" />
      </router-link>

      <div class="menu-header">
        <nav class="menu-container">
          <router-link to="/home" class="menu-item">
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_booth2"></div>
            <div class="menu-text">
              Hall d'exposition
            </div>
          </router-link>
          <router-link to="/serious-game" class="menu-item">
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_seriousgames"></div>
            <div class="menu-text">
              Serious game
            </div>
          </router-link>
          <router-link to="/a-propos" class="menu-item">
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_information"></div>
            <div class="menu-text">
              A propos
            </div>
          </router-link>
          <div class="menu-item" @click="refresh">
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_refresh"></div>
            <div class="menu-text">
              Actualiser
            </div>
          </div>
        </nav>
      </div>

      <!-- MENU RESP -->
      <div class="menu-header-resp">
        <div
          class="burger-nav"
          @click="changeState"
          :class="open ? 'open' : ''"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <nav class="menu-header-resp-content" v-if="open">

          <router-link to="/home" class="menu-item">
            <div class="icon-menu icon-otw_icon_booth2"></div>
            <div class="menu-text">
              Hall d'exposition
            </div>
          </router-link>
          <router-link to="/serious-game" class="menu-item">
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_seriousgames"></div>
            <div class="menu-text">
              Serious game
            </div>
          </router-link>
          <router-link to="/a-propos" class="menu-item">
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_information"></div>
            <div class="menu-text">
              A propos
            </div>
          </router-link>
          <div class="menu-item" @click="refresh">
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_refresh"></div>
            <div class="menu-text">
              Actualiser
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderApp",
  data() {
    return {
      soundPlay : true
    }
  },
  methods: {
    switchMenu() {
      this.$store.commit("switchUserMenu");
    },
    changeState() {
      this.$store.commit("setMenuOpen");
    },
    refresh() {
      this.$store.commit('refreshStands')
    },
    soundSwitch() {
      // if(this.soundPlay ) {
      //   document.getElementById("sound").pause()
      // }else {
      //   document.getElementById("sound").play()
      // }

      //this.soundPlay = !this.soundPlay

      if(this.$store.state.SOUND) {
        this.$store.commit('stopSound')
      }else {
        this.$store.commit('playSound')
      }

    }
  },
  computed: {

    open() {
      return this.$store.getters.getMenuOpen;
    },

  },
  mounted() {
    document.getElementById("sound").play()
  }
};
</script>

<style lang="scss" scoped>
.msg-notification {
  width: 18px;
  height: 18px;
  border-radius: 50px;
  font-size: 10px;
  color: #fff;
  line-height: 19px;
  text-align: center;
  background: $main-color;
  position: fixed;
  margin: -48px 5px 8px 70px;
}
.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
  background: #fff;
  box-shadow: 0 2px 10px 10px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 85px;
  width: $box-width;
  max-width: 95%;
}

.logo-header {
  display: flex;
  align-items: center;
}

.logo-header img {
  height: 65px;
  width:auto;
  max-height:65px;
}

.menu-header {
  display: flex;
  justify-content: flex-end;
}

.menu-container {
  display: flex;
  margin-right:30px;
}

.icon-menu:before {
  font-size: 22px;
  color: $menu-color;
}

.menu-item:hover .icon-menu:before,
.router-link-exact-active .icon-menu:before {
  color: $main-color;
}

.menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  border-left: 1px solid $menu-color;
  height: 85px;
  position: relative;
  cursor: pointer;
}

.menu-item:last-child {
  border-right: 1px solid $menu-color;
}

.menu-highlight {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: #fff;
}

.menu-item:hover .menu-highlight,
.router-link-exact-active .menu-highlight {
  background: $main-color;
}

.menu-text {
  display: none;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  color: $main-color;
  font-size: 10px;
  font-display: $font-bold;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.menu-item:hover .menu-text,
.router-link-exact-active .menu-text {
  display: flex;
}

/* MENU RESP */
.menu-header-resp {
  display: none;
}

.burger-nav {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.burger-nav span {
  display: block;
  position: absolute;
  height: 7px;
  width: 100%;
  background: $main-color;
  border-radius: 7px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.burger-nav span:nth-child(1) {
  top: 0px;
}

.burger-nav span:nth-child(2),
.burger-nav span:nth-child(3) {
  top: 14px;
}

.burger-nav span:nth-child(4) {
  top: 28px;
}

.burger-nav.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.burger-nav.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.burger-nav.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.burger-nav.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.menu-header-resp-content {
  position: fixed;
  top: 85px;
  width: 300px;
  right: 0;
  background: #fff;
  border-bottom: 1px solid $menu-color;
}

.menu-header-resp-content .menu-item {
  width: calc(100% - 40px);
  border: none;
  height: 40px;
  justify-content: flex-start;
  padding: 0 20px;
  align-items: center;
  text-decoration: none;
}

.menu-header-resp-content .menu-item .menu-text {
  display: flex;
  position: relative;
  width: calc(100% - 22px);
  height: 40px;
  align-items: center;
  bottom: 0;
  text-decoration: none;
  text-align: left;
  justify-content: flex-start;
  margin-left: 20px;
}

.menu-header-resp-content .router-link-exact-active.menu-item {
  background: $main-color;
}

.menu-header-resp-content .router-link-exact-active .menu-highlight {
  display: none;
}

.menu-header-resp-content .router-link-exact-active.menu-item .icon-menu:before,
.menu-header-resp-content .router-link-exact-active.menu-item .menu-text {
  color: #fff;
}

.menu-resp-sep {
  margin: 5px 0;
  height: 1px;
  width: 100%;
  background: $menu-color;
}

/* Account */

.menu-user {
  position: relative;
  width: 250px;
  max-width: 250px;
}

.menu-user-main {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid $menu-color;
  height: 85px;
  cursor: pointer;
}

.menu-user .avatar img {
  height: 45px;
  width: 45px;
}

.menu-user .avatar {
  height: 45px;
  width: 45px;
  min-width: 45px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 20px;
  background-position: center !important ;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.avatar-default {
  height: 45px;
  width: 45px;
  min-width: 45px;
  margin-right: 20px;
  border-radius: 50%;
  color: #fff;
  font-family: $font-bold;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.menu-user-sub {
  position: absolute;
  top: 85px;
  right: 0;
  left: 0;
  z-index: 99;
}

.menu-user-sub a {
  text-decoration: none;
}

.submenu-item {
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: $font-bold;
  font-weight: 700;
}

a.submenu-item {
  text-decoration: none;
}

.submenu-item:hover {
  background: $main-color;
}

.submenu-item:hover .icon-submenu:before {
  color: #fff;
}

.submenu-item:hover .submenu-text {
  color: #fff;
}

.submenu-item:last-child {
  border-bottom: 1px solid $menu-color;
}

.icon-submenu {
  margin: 0 20px;
}

.icon-submenu:before {
  font-size: 18px;
  color: $main-color;
}

.submenu-text {
  color: $text-color;
  letter-spacing: 1px;
  text-decoration: none;
}

.logout-item {
  background: $menu-color;
}

.logout-item .icon-submenu:before {
  color: #fff;
}

.logout-item .submenu-text {
  color: #fff;
}

.user-infos {
  margin-right: 20px;
}

.user-name {
  font-size: 15px;
  font-family: $font-bold;
  font-weight: 700;
  text-align: center;
  color: $main-color;
}

.user-company {
  font-size: 10px;
  font-family: $font-bold;
  font-weight: 700;
  text-align: center;
  color: $text-color;
  text-transform: uppercase;
}

.toggle-menu {
  display: inline-block;
  height: 0;
  width: 0;
  border-right: 6px solid transparent;
  border-bottom: 10px solid $menu-color;
  border-left: 6px solid transparent;
  transform: rotate(180deg);
}

.toggle-menu.reverse {
  transform: rotate(0);
}

.menu-resp-logout {
  background: $menu-color;
  margin-top: -5px;
}

.menu-resp-logout .icon-menu:before {
  color: #fff;
}

.menu-resp-logout .menu-text {
  color: #fff;
}

.header-sound-control{
  position: absolute;
  top:10px;
  right:10px;
}

.audio-contoller {
  cursor: pointer;
}

.sound-icon {
  height:28px;
  width:28px;
}

@media screen and (max-width: 1080px) {
  .menu-header-resp {
    display: flex;
  }

  .menu-header {
    display: none;
  }

  .header-sound-control {
    right:80px;
    top:30px;
  }
}
</style>