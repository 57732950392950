<template>
  <div class="footer-container" >
    <div class="row">
      <div class="col-33 flex-left">
        <div class="footer-bloc">
          <div class="footer-title">
            Informations Légales
          </div>
          <nav>
            <a
              class="footer-link"
              href="https://www.frtpoccitanie.fr/"
              target="_blank"
              >
              Mentions Légales
            </a>
            <!-- <a
              class="footer-link"
              :href="legal"
              target="_blank"
              >
              Politique de Confidentialité
              </a
            >
            <a
              class="footer-link"
              :href="legal"
              target="_blank"
              >
              Informations sur les Cookies
              </a -->
          </nav>
        </div>
      </div>
      <div class="col-33 flex-center">
        <div class="footer-bloc">
          <div class="footer-title">
            Restez connectés avec nous
          </div>
          <nav>
            <a
              class="footer-link"
              href="https://www.frtpoccitanie.fr/"
              target="_blank"
              >
              A propos de nous
              </a
            >
            <a
              class="footer-link"
              href="https://www.frtpoccitanie.fr/contact"
              target="_blank"
              >
              Contactez-nous
              </a
            >
          </nav>
        </div>
      </div>
      <div class="col-33 flex-right">
        <div class="footer-bloc">
          <div class="footer-title">
            Suivez-nous
          </div>
          <div class="social-network">
            <!-- <a
              :href="facebook"
              target="_blank"
              class="social-network-item icon-otw_icon_facebook"
            ></a>
            <a
              :href="instagram"
              target="_blank"
              class="social-network-item icon-otw_icon_instagram"
            ></a> -->
            <a
              :href="linkedin"
              target="_blank"
              class="social-network-item icon-otw_icon_linkedin"
            ></a>
            <a
              :href="twitter"
              target="_blank"
              class="social-network-item icon-otw_icon_twitter"
            ></a>
            <!-- <a
              :href="youtube"
              target="_blank"
              class="social-network-item icon-otw_icon_youtube"
            ></a> -->
          </div>

          
        </div>
      </div>
    </div>
    <div class="footer-infos">
      <div class="footer-logo">
      <!-- FOOTER TYPE 1 -->
      
      <a  href="https://www.frtpoccitanie.fr/" target="_blank">
        <img src="./../assets/img/logo-footer.png" alt="logo footer" />
      </a>
      <!-- FOOTER TYPE 2 ou 3 -->

      </div>
      <div class="footer-sep"></div>
      <div class="footer-copyright">
        <p>
          {{ `© ${new Date().getFullYear()} FRTP OCCITANIE`}}

        </p>
      </div>
    </div>
    <div class="footer-version" @click="logout">v1.2</div>
  </div>
</template>

<script>
export default {
  name: "FooterApp",
  data(){
    return {
      facebook : null,
      linkedin : null,
      youtube : null,
      instagram : null,
      twitter : null,
      about : null,
      contact : null,
      legal : null
    }
  },
  methods : {
    logout() {
      this.$store.commit('logout')
    }
  },
  created() {   
    this.facebook = 'https://www.facebook.com/OTWVirtualConventions/'
    this.linkedin = 'https://fr.linkedin.com/company/frtpmidipy'
    this.youtube = 'https://www.youtube.com/channel/UCTp4xlSJDYVH151n8GxCrfw/?guided_help_flow=5&disable_polymer=true'
    this.instagram = 'https://www.instagram.com/otwvirtualconventions/'
    this.twitter = 'https://twitter.com/frtp_midipy?lang=fr'
    this.about = 'https://otwvirtualconventions.com/'
    this.contact = 'https://otwvirtualconventions.com/'
    this.legal = 'https://otwvirtualconventions.com/'
  }
};
</script>

<style lang="scss" scoped>
.footer-container {
  background: #1b1b1b;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 140px;
  position: relative;
}

.footer-container .row {
  width: $box-width;
  max-width: 95%;
  padding: 30px 0 10px 0;
}

.footer-title {
  color: #fff;
  font-size: 15px;
  font-family: $font-bold;
  font-weight: 700;
  margin-bottom: 20px;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.footer-bloc {
  padding: 0 5%;
  width: 90%;
}

.footer-bloc nav {
  display: flex;
  flex-direction: column;
}

.footer-bloc nav a {
  color: #fff;
  font-size: 15px;
  text-decoration: none;
  margin-bottom: 15px;
  cursor: pointer;
}

.footer-bloc nav a:hover {
  text-decoration: underline;
}

/* footer infos */

.footer-infos {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  align-items: center;
}

.footer-copyright p {
  color: #fff;
  font-size: 15px;
}

.footer-logo img {
  max-height: 60px;
}

.footer-sep {
  margin: 0 40px;
  width: 1px;
  background: #fff;
  height: 40px;
}

.social-network {
  display: flex;
}

.social-network-item {
  margin: 0 5px;
}

.social-network-item:before {
  color: #fff;
  font-size: 25px;
  cursor: pointer;
}
.social-network-item:hover:before {
  color: $main-color;
}

.social-network-item:first-child {
  margin-left: 0;
}

.footer-logo {
  display: flex;
  flex-direction: column;
}

.footer-logo p {
  font-size:12px;
  color:#fff;
  margin-bottom: 5px;
  text-align: center;
}

.footer-logo a {
  font-size:12px;
  color:#fff;
  text-align: center;
  text-decoration: none;
  font-style:italic;
  margin-top:5px;
}

.footer-logo a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .footer-container .row {
    flex-direction: column;
    align-items: center;
  }

  .footer-container .row  .col-33 {
    margin-bottom: 30px;
    width: 100%;

  }

  .footer-bloc nav {
    align-items: center;
  }

  .footer-bloc {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .footer-infos{
    flex-direction: column;
  }

  .footer-sep {
    display: none;
  }

  .footer-copyright {
    margin-top: 10px;
  }
}
</style>
