import Vue from "vue";
import Router from "vue-router";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "*",
      redirect: "/",
    },
    {
      path: "/home",
      name: "Home",
      component: () => import("./views/Home.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/stand/:id",
      name: "Stand",
      component: () => import("./views/Stand.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("./views/Login.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/",
      name: "Welcome",
      component: () => import("./views/Welcome.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/versions",
      name: "Versions",
      component: () => import("./views/Versions.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/a-propos",
      name: "About",
      component: () => import("./views/About.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/serious-game",
      name: "Game",
      component: () => import("./views/Game.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/stats",
      name: "Stats",
      component: () => import("./views/Stats.vue"),
      meta: {
        requiresAuth: false,
      },
    },
  ],
});

/* verification qu'on est connecté sinon on redirige */
router.beforeEach((to, from, next) => {

  store.commit("errorClose");
  store.commit("closeMenu");
  const currentUser = store.getters.getAuth;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) next("/");
  else next();
});

export default router;
